import { useEffect, useState } from 'react';
import { NftMint } from 'modules/home/slice';
import { useDispatch, useSelector } from 'react-redux';
import { getLoaderETH } from 'modules/home/selector';
import Loader from 'modules/common/components/loader';

const EthMint = ({ ETHPrice, Balance, Supply, wallet, direction }) => {
  const dispatch = useDispatch();

  const ethLoading = useSelector(getLoaderETH);

  const [ethPrice, setEthPrice] = useState(0);

  const [quantity, setQuantity] = useState(1);

  const [validPrice, setValidPrice] = useState(false);

  const increaseQuantity = () => {
    if (quantity !== Supply.maxSupply) setQuantity(quantity + 1);
  };

  useEffect(() => {
    if (ETHPrice.eth > 0) {
      const total = quantity * ETHPrice.eth;
      setEthPrice(total.toFixed(3));
      const validate = Balance >= total;
      setValidPrice(validate);
    }
  }, [quantity, ETHPrice]);

  const decreaseQuantity = () => {
    if (quantity !== 1) setQuantity(quantity - 1);
  };

  const nftMint = async () => {
    dispatch(NftMint({ value: `${ETHPrice.eth * quantity}`, quantity }));
  };

  const goBack = () => {
    direction(true);
  };

  return (
    <>
      <div className="text-center text-l font-semibold">
        Connected Wallet {wallet.slice(0, 5)}...{wallet.slice(-4)}
      </div>
      <div className="flex justify-center mt-2 mb-3 text-xl italic font-semibold">
        {!validPrice ? 'Insufficient ETH in your wallet.' : ''}
      </div>

      <div className="text-center text-3xl text-button-color font-semibold">
        {ethPrice} ETH + Gas
      </div>
      <div className="flex justify-center mt-2 ">
        <button
          type="button"
          onClick={decreaseQuantity}
          className="bg-[#f69ffe] text-white font-bold px-[1rem] py-[0.5rem] text-3xl min-w-[50px]"
        >
          -
        </button>
        <input
          className="text-button-color font-bold text-xl text-center max-w-[100px]"
          readOnly
          value={quantity}
        />

        <button
          type="button"
          onClick={increaseQuantity}
          className="bg-[#f69ffe] bg-theme-lime text-white px-[1rem] py-[0.5rem] text-3xl min-w-[50px]"
        >
          +
        </button>
      </div>
      <div className="flex justify-center mt-2">
        {ethLoading ? (
          <Loader />
        ) : (
          <button
            type="button"
            className={`text-theme-color uppercase font-bold px-[1rem] py-[0.5rem] rounded-sm text-lg min-w-[200px] ${
              !validPrice ? 'bg-gray-400' : 'bg-button-color'
            }`}
            disabled={!validPrice}
            onClick={() => nftMint()}
          >
            Mint
          </button>
        )}
      </div>
      <div className="flex justify-center mt-2 text-xl text-text-color font-bold">
        Total Minted: {Supply.totalSupply} / {Supply.maxSupply}
      </div>

      <div className="flex justify-center mt-2">
        <button type="button" className="text-theme-lime text-lg font-bold" onClick={goBack}>
          Back
        </button>
      </div>
    </>
  );
};

export default EthMint;
