/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

/**
 * Initial State of check connection.
 */

export const initialState = {
  walletList: [],
  loading: false,
};

/**
 * Check connection function has 3 reducers.
 * 1. getWallet - getting all wallets using a web3 call
 * 2. getWalletSucceeded - if succeed, set all wallets to the wallet list.
 * 3. getWalletFailed - if Failed, retuns the failure.
 */

const walletSlice = createSlice({
  name: 'feature/wallet',
  initialState,
  reducers: {
    getWallet(state) {
      state.loading = true;
    },
    getWalletSucceeded(state, action) {
      state.walletList = action.payload;
      state.loading = false;
    },
    getWalletFailed(state) {
      state.loading = false;
    },
  },
});

export const { getWallet, getWalletSucceeded, getWalletFailed } = walletSlice.actions;

export default walletSlice.reducer;
