import React from 'react';

const Loader = () => (
  <button
    type="button"
    className="text-white px-[1rem] py-[0.5rem] rounded-sm text-lg min-w-[200px] bg-pink-300 font-semibold uppercase mt-6 flex cursor-wait"
    disabled
  >
    <svg
      className="mr-3 h-5 w-5 animate-spin text-theme-color"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
    Loading...
  </button>
);

export default Loader;
