import Home from 'modules/home/components';
import ROUTES from 'modules/common/constants/route';

/**
 * Define the routeing structure using array. Here include the nested routeing as well.
 * And also define the auth wrapper and private routers for helping to role based routes
 */
const routes = [
  {
    path: ROUTES.ROOT,
    element: <Home />,
  },
];
export default routes;
