/* eslint-disable import/prefer-default-export */

import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

export const selectWallet = (state) => state['feature/wallet'] || initialState;

export const getNetwork = createSelector(
  [selectWallet],
  (usersState) => usersState.walletList.network
);
export const getETHBalance = createSelector(
  [selectWallet],
  (usersState) => usersState.walletList.ETHbalance
);
export const getAddress = createSelector(
  [selectWallet],
  (usersState) => usersState.walletList.address
);
export const getLoader = createSelector([selectWallet], (usersState) => usersState.loading);
