/* eslint-disable no-param-reassign */

import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  ethPrice: 0,
  loading: false,
  mint: '',
  errorMessage: '',
  supply: '',
  paperUrl: '',
};

export const fetchETHSlice = createSlice({
  name: 'feature/native-mint',
  initialState,
  reducers: {
    fetchPrice(state) {
      state.loading = true;
    },
    fetchPriceSucceeded(state, action) {
      state.loading = false;
      state.ethPrice = action.payload;
    },
    fetchPriceFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    NftMint(state) {
      state.loading = true;
    },
    NftMintSucceeded(state, action) {
      state.mint = action.payload.hash;

      state.loading = false;
    },
    NftMintFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    NftSupply(state) {
      state.loading = true;
    },
    NftSupplySucceeded(state, action) {
      state.supply = action.payload;
      state.loading = false;
    },
    NftSupplyFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
    fetchPaper(state) {
      state.loading = true;
    },
    fetchPaperSucceeded(state, action) {
      state.loading = false;
      state.paperUrl = action.payload;
    },
    fetchPaperFailed(state, action) {
      state.loading = false;
      state.errorMessage = action.payload;
    },
  },
});

export const {
  fetchPrice,
  fetchPriceSucceeded,
  fetchPriceFailed,
  NftMint,
  NftMintSucceeded,
  NftMintFailed,
  NftSupply,
  NftSupplySucceeded,
  NftSupplyFailed,
  fetchPaper,
  fetchPaperSucceeded,
  fetchPaperFailed,
} = fetchETHSlice.actions;

export default fetchETHSlice.reducer;
