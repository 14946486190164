/* eslint-disable import/prefer-default-export */

import { ethers } from 'ethers';
import axios from 'axios';
import ENVIRONMENT from 'config/environment';
import wmw from 'config/abis/wmw.json';

// Set the contract address and ABI of the smart contract
const contractAddress = ENVIRONMENT.CONTRACT_ADDRESS;
const contractABI = wmw;

// get ETH price from external endpoint
export const getPrice = async () => {
  const response = await axios.get(ENVIRONMENT.ETH_PRICE);
  return response.data.data;
};

export const nftMint = async (value, quantity) => {
  // Connect to the Ethereum network
  const ethersProvider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = ethersProvider.getSigner();
  // Get the contract instance
  const contract = new ethers.Contract(contractAddress, contractABI, signer);

  // call mintPublic function from contract
  const mintTxn = await contract.mintPublic(quantity, {
    value: ethers.utils.parseEther(value),
  });

  return mintTxn;
};

export const Supply = async () => {
  // Connect to the Ethereum network
  
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  const signer = provider.getSigner();
  const contract = new ethers.Contract(contractAddress, contractABI, signer);

  // get max supply and total-supply from smart contract
  let maxSupply = await contract.maxSupply();
  let totalSupply = await contract.totalSupply();

  // convert big-number to number
  maxSupply = maxSupply.toNumber();
  totalSupply = totalSupply.toNumber();

  console.log(maxSupply)

  return { maxSupply, totalSupply };
};

export const connectPaper = async (eth) => {
  const response = await axios.post(ENVIRONMENT.PAPER_API, { valueEth: eth });

  return response.data;
};
