import MainModel from 'modules/home/components/main-model';
import React from 'react';

const Home = () => (
  <div>
    <div className="flex justify-center items-center h-screen">
      <MainModel />
    </div>
  </div>
);

export default Home;
