/**
 * All constants relatd to home component
 */

export const PAYMENTMETHODS = [
  { id: 'eth', title: 'ETH' },
  { id: 'usd', title: 'USD' },
];

export const LABLES = {
  MINT_TEXT: 'Mint with Women Making Waves',
  PAYMENT_TYPE_TEXT: 'What payment method will you use?',
};
