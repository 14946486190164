/* eslint-disable no-nested-ternary */
import { connectWallet } from 'config/web3/config';
import { getAddress, getETHBalance, getLoader, getNetwork } from 'config/web3/selector';
import { getEthPrice, getTotalMinted, getPaperUrl, getLoaderETH } from 'modules/home/selector';
import { fetchPrice, NftSupply, fetchPaper } from 'modules/home/slice';
import { getWallet } from 'config/web3/slice';
// import IMAGES from 'modules/common/constants/images';
import { PAYMENTMETHODS as paymentMethods, LABLES } from 'modules/home/constants';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import EthMint from 'modules/home/components/eth-mint';
import logo from 'modules/assets/logo.png';
import ENVIRONMENT from 'config/environment';
import Loader from 'modules/common/components/loader';
import axios from 'axios';

const MainModel = () => {
  const dispatch = useDispatch();

  // Use to check whether user is connected. Returns connected wallet address if connected.
  // const wallet = useSelector(selectWallet);
  const address = useSelector(getAddress);
  const network = useSelector(getNetwork);
  const balance = useSelector(getETHBalance);
  const loading = useSelector(getLoader);

  const paperUrl = useSelector(getPaperUrl);
  const ethLoading = useSelector(getLoaderETH);

  // get price from the api end point
  const ethPrices = useSelector(getEthPrice);

  // get total minted
  const supply = useSelector(getTotalMinted);

  // store connected wallet address
  const [connected, setConnected] = useState('');

  // store choosed payment mode. default payment mode is 'ETH'
  const [paymentType, setPaymentType] = useState('eth');

  const [direction, setDirection] = useState(0);

  const [networkType, setNetworkType] = useState('');

  // trigger wallet connect function.
  const connectAWallet = async () => {
    const connection = await Promise.resolve(connectWallet());

    if (connection.address) {
      setConnected(connection.address);
    }

    setDirection(false);
  };

  // Catch all radio button actions and store inside the payment state
  const handleRadioChange = (e) => {
    setPaymentType(e.target.value);
  };

  const changeDirection = () => {
    setDirection(false);
  };

  // Check wallet is connected or not.
  // get eth price
  useEffect(() => {
    dispatch(NftSupply());
    dispatch(getWallet());
    // setLoading(false);
    Promise.resolve(dispatch(getWallet()));
    dispatch(fetchPrice());
  }, [connected]);

  useEffect(() => {
    setNetworkType(network);
  }, [network]);

  // handle usd payement from paper
  const handleUSD = async () => {
    dispatch(fetchPaper(ethPrices.eth));
    const res = await axios.post(ENVIRONMENT.PAPER_API, { valueEth: ethPrices.eth });
    window.location.href = res.data;
    // if (paperUrl !== '') {
    //   // 👇️ navigate to Paper
    //   window.location.href = paperUrl;
    // }
  };

  return (
    <div
      className={`bg-theme-color w-[80%] lg:w-[60%] xl:w-[45%] h-auto text-white rounded px-16 lg:px-28 min-h-[500px] 2xl:min-h-[700px] flex flex-col justify-between drop-shadow-[0_35px_30px_rgba(0,0,0,1)] ${
        ENVIRONMENT.NETWORK !== networkType ? 'py-20' : 'py-12'
      }`}
    >
      <div className="flex justify-center">
        <img src={logo} alt="logo" className="w-64" />{' '}
      </div>
      <div
        className={`border-b-2 border-pink-500 ${
          ENVIRONMENT.NETWORK !== networkType ? 'my-16' : 'my-8'
        }`}
      />
      <div className="ext-center text-2xl 2xl:text-3xl text-pink-300 font-semibold text-center">
        {LABLES.MINT_TEXT}
      </div>
      {direction === true || !address ? (
        <>
          <div
            className={`text-center text-lg text-pink-400 font-bold ${
              ENVIRONMENT.NETWORK !== networkType ? 'my-8' : 'my-4'
            } `}
          >
            {LABLES.PAYMENT_TYPE_TEXT}
          </div>

          <div className=" sm:items-center space-y-0 space-x-8 flex justify-center ">
            {paymentMethods.map((paymentMethod) => (
              <div key={paymentMethod.id} className="flex items-center">
                <input
                  id={paymentMethod.id}
                  name="payment-method"
                  type="radio"
                  value={paymentMethod.id}
                  defaultChecked={paymentMethod.id === 'eth'}
                  onChange={handleRadioChange}
                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                />
                <label
                  htmlFor={paymentMethod.id}
                  className="ml-3 block text-xl text-pink-400 font-bold"
                >
                  {paymentMethod.title}
                </label>
              </div>
            ))}
          </div>
          <div className="flex justify-center mt-2">
            {ethLoading || loading === true ? (
              <Loader />
            ) : (
              <button
                type="button"
                onClick={() =>
                  paymentType === 'eth'
                    ? address
                      ? changeDirection()
                      : connectAWallet()
                    : handleUSD()
                }
                className="text-white px-[1rem] py-[0.5rem] rounded-sm text-lg min-w-[200px] bg-pink-500 font-semibold uppercase mt-6"
              >
                {paymentType === 'eth' ? (address ? 'Next' : 'Buy') : 'Next'}
              </button>
            )}
          </div>
        </>
      ) : (
        <div>
          {ENVIRONMENT.NETWORK !== networkType ? (
            <div className="flex-col text-center mt-2">
              <p>Minting is not available. </p>
              <p>Please make sure you are connected to {ENVIRONMENT.NETWORK}</p>
              <p>and Reload the Page.</p>
            </div>
          ) : (
            <EthMint
              ETHPrice={ethPrices}
              Balance={balance}
              Supply={supply}
              wallet={address}
              direction={(value) => {
                setDirection(value);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default MainModel;
