// /* eslint-disable import/no-cycle */
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import WalletReducer from 'config/web3/slice';
import fetchETHReducer from 'modules/home/slice';
import rootSaga from 'root-saga/index';

const sagaMiddlewware = createSagaMiddleware();

/**
 * Activate wishlist for users' slice
 */
const rootPersistConfig = {
  key: 'root',
  storage,
  blacklist: ['feature/wallet'],
};

/**
 * All reducers combination
 */
const reducers = combineReducers({
  'feature/wallet': WalletReducer,
  'feature/native-mint': fetchETHReducer,
});

/**
 * Define the slice that needs to persist.
 */

const persistedReducer = persistReducer(rootPersistConfig, reducers);

/**
 * Register all slices to store.
 */
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(sagaMiddlewware),
});

/**
 * Apply redux-saga middleware
 */

sagaMiddlewware.run(rootSaga);

export default store;
