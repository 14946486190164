/* eslint-disable */
import { takeLatest, put, fork, call } from 'redux-saga/effects';
import {
  fetchPrice,
  fetchPriceSucceeded,
  fetchPriceFailed,
  NftMint,
  NftMintSucceeded,
  NftMintFailed,
  NftSupply,
  NftSupplySucceeded,
  NftSupplyFailed,
  fetchPaper,
  fetchPaperSucceeded,
  fetchPaperFailed,
} from './slice';
import { getPrice, nftMint, Supply, connectPaper } from 'modules/home/functions';
import request from 'config/utils/request';
/**
 * Haddled all Navtive MINT related sagas
 */

function* getEthPrice() {
  try {
    const response = yield call(getPrice);
    yield put(fetchPriceSucceeded({ ...response }));
  } catch (error) {
    yield put(fetchPriceFailed(error.message));
  }
}

function* OngetEthPrice() {
  yield takeLatest(fetchPrice.type, getEthPrice);
}

function* mintNFT({ payload }) {
  try {
    const response = yield call(nftMint, payload.value, payload.quantity);
    yield put(NftMintSucceeded({ ...response }));
  } catch (error) {
    yield put(NftMintFailed(error.message));
  }
}

function* OnmintNFT() {
  yield takeLatest(NftMint.type, mintNFT);
}

function* NFTSupply() {
  try {
    const response = yield call(Supply);
    yield put(NftSupplySucceeded({ ...response }));
  } catch (error) {
    yield put(NftSupplyFailed(error.message));
  }
}

function* OnNFTSupply() {
  yield takeLatest(NftSupply.type, NFTSupply);
}

function* getPaperUrl({ payload }) {
  try {
    const response = yield call(connectPaper, payload);
    yield put(fetchPaperSucceeded(response));
  } catch (error) {
    yield put(fetchPaperFailed(error.message));
  }
}

function* OngetPaperUrl() {
  yield takeLatest(fetchPaper.type, getPaperUrl);
}

export const EthSagas = [
  fork(OngetEthPrice),
  fork(OnmintNFT),
  fork(OnNFTSupply),
  fork(OngetPaperUrl),
];
