/* eslint-disable import/prefer-default-export */

import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './slice';

export const getPrice = (state) => state['feature/native-mint'] || initialState;

export const getEthPrice = createSelector([getPrice], (state) => state.ethPrice);

export const getTotalMinted = createSelector([getPrice], (state) => state.supply);

export const getMintHash = createSelector([getPrice], (state) => state.mint);

export const getPaperUrl = createSelector([getPrice], (usersState) => usersState.paperUrl);
export const getLoaderETH = createSelector([getPrice], (usersState) => usersState.loading);
