/**
 * Define all the .env file related keys
 */
const ENVIRONMENT = {
  INFURA_KEY: process.env.REACT_APP_INFURA_KEY,
  CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS,
  NETWORK: process.env.REACT_APP_NETWORK,
  CONTRACT_URL: process.env.REACT_APP_CONTRACT_URL,
  ETH_PRICE: process.env.REACT_APP_GET_ETH_PRICE,
  PAPER_API: process.env.REACT_APP_PAPER_API,
  TRANSACTION_URL: process.env.REACT_APP_VIEW_TRANSACTION,
};

//
export default ENVIRONMENT;
