/* eslint-disable */
import { takeLatest, put, fork, call } from 'redux-saga/effects';
import { getWallet, getWalletSucceeded, getWalletFailed } from './slice';
import { checkConnection } from './config';

/**
 * Haddled all check connection related sagas
 */

function* onLoadConnectionAsync() {
  try {
    const response = yield call(checkConnection);

    if (response.address) {
      yield put(getWalletSucceeded({ ...response }));
    }
  } catch (error) {
    yield put(getWalletFailed(error));
  }
}

function* onLoadConnection() {
  yield takeLatest(getWallet.type, onLoadConnectionAsync);
}

export const walletSagas = [fork(onLoadConnection)];
