import Web3Modal from 'web3modal';
import { ethers } from 'ethers';
import { CoinbaseWalletSDK } from '@coinbase/wallet-sdk';
import WalletConnect from '@walletconnect/web3-provider';
import ENVIRONMENT from 'config/environment';
import Web3 from 'web3';

/**
 * Assigned all wallet providers to the app.
 * have used 4 wallet providers (metamask, WalletConnect, Coinbase, Binancechain)
 */

const providerOptions = {
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      appName: 'WMW Mint Frontend',
      infuraId: { 1: ENVIRONMENT.INFURA_KEY }, //
    },
  },
  binancechainwallet: {
    package: true,
  },
  walletconnect: {
    package: WalletConnect, // required
    options: {
      infuraId: ENVIRONMENT.INFURA_KEY, // required
    },
  },
};

/**
 * Wallet connecting function implementation.
 * @returns connected wallet address
 */

export const connectWallet = async () => {
  let data;
  try {
    const web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions,
    });
    const web3ModalInstance = await web3Modal.connect();
    const web3ModalProvider = new ethers.providers.Web3Provider(web3ModalInstance);
    const signer = web3ModalProvider.getSigner();
    data = { address: await signer.getAddress() };
  } catch (error) {
    console.error(error);
  }
  return data;
};

/**
 * This function used to continuously check whether wallet is connected.
 * @returns if wallet is connected, connected wallet address will be returned.
 */
const web3 = new Web3(window.ethereum);
export const checkConnection = async () => {
  if (typeof window.ethereum !== 'undefined') {
    console.log('MetaMask is installed!');
  }
  const accounts = await window.ethereum.request({ method: 'eth_accounts' });
  const networkType = await web3.eth.net.getNetworkType();
  const balance = await web3.eth.getBalance(accounts[0]);
  const balanceInEther = web3.utils.fromWei(balance, 'ether');
  const account = { address: accounts[0], network: networkType, ETHbalance: balanceInEther };
  return account;
};
